import React from 'react';
import { inject, observer } from 'mobx-react';
import { browserHistory } from 'react-router';
import { Form, Button, Popup, Segment, Container, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import { uniqueId, isEqual } from 'lodash';
import gql from 'graphql-tag';
import Dropzone from 'react-dropzone';
import 'react-html5video/dist/styles.css';

import TextareaAutosize from 'react-textarea-autosize';
import Avatar from '../../components/Avatar';
import Modal from '../../components/Modal';
import AudioPlayer from '../../components/AudioPlayer';
import AttachmentPreview from '../../components/AttachmentPreview';
import FeaturesModal from '../../components/FeaturesModal';
import Responsive from '../../components/Responsive';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import VideoPlayer from '../../components/VideoPlayer';

const ATTACHMENT_LIMIT = 20;
const ATTACHMENT_SIZE_MB = 25;
const BYTES_IN_ONE_MB = 1e+6;

const uploadMediaMutation = `mutation UploadMedia ($entityId: Int!) {
  uploadMedia (input: {entityId: $entityId, transcode: true}) {
    media {
      id: dbId
      filename
      uri
      thumbnail
    }
  }
}`;


@inject('store', 'api', 'client')
@graphql(gql`mutation createReply($createReplyMutation: CreateReplyInput!) {
  createReply(input: $createReplyMutation) {
    clientMutationId
  }
}`)
@graphql(gql`mutation updateReply($updateReplyInput: UpdateReplyInput!) {
  updateReply(input: $updateReplyInput) {
    clientMutationId
  }
}`, {
  name: 'updateReply',
  options: {
    awaitRefetchQueries: true,
    refetchQueries: ['ReplyListEntityQuery', 'ReplyListOrganizationQuery']
  }
})
@observer
export default class ReplyForm extends Responsive {
  constructor(props) {
    super(props);
    this.state = {
      content: props.content || '',
      medias: props.medias || [],
      loading: false
    };
  }

  rules = {
    medias: `maxLength:${ATTACHMENT_LIMIT}`
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { mutate, message_id, entity_id, parent_id, store } = this.props;

    this.setWritingReply(false);

    const content = this.state.content.replace(/<(?!a)(?!\/a>)/gi, '&lt;');
    try {
      await mutate({
        variables: {
          createReplyMutation: {
            content,
            medias: this.state.medias.filter(media => media.response).map(media => ({
              filename: media.response.filename,
              type: utils.getMediaType(media.type, media.response.filename)
            })),
            messageId: message_id,
            entityId: entity_id,
            parentId: typeof parent_id === 'string' ? parseInt(parent_id, 10) : parent_id,
          }
        }
      });

      this.setState({ content: '', medias: [], loading: false });
      this.props.onSubmit();
    } catch (err) {
      this.setState({ loading: false });

      if (err.graphQLErrors[0].message === 'Cannot perform a reply on deleted messages') {
        store.snackbar = { active: true, message: __('This message has been deleted'), success: false };

        const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
          (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');

        browserHistory.push(link);

        await this.props.client.reFetchObservableQueries();
      } else {
        store.snackbar = { active: true, message: utils.handleError(err), success: false };
      }
    }
  };


  openPaywall(feature) {
    this.props.store.appends.push(<FeaturesModal feature={feature} />);
  }

  onFileDrop = async (accepted, rejected) => {
    const { store } = this.props;

    if (rejected.length > 0) {
      if (rejected.find(file => file.size > ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB)) {
        store.snackbar = { active: true, message: __('File too big, maximum size is %s MB.', ATTACHMENT_SIZE_MB), success: false, dismissAfter: 5000 };
      } else {
        store.snackbar = { active: true, message: __('File format not accepted, please provide an image, video or PDF.'), success: false };
      }
    }

    const attachmentsCount = this.state.medias.length;

    const organization = this.props.store.entity ? this.props.store.entity.organization : this.props.store.currentOrganization;


    const canSendVideos = (organization && organization.features && organization.features.videos) || this.props.store.currentUser.isMaster;
    const canSendAudio = (organization && organization.features && organization.features.audios) || this.props.store.currentUser.isMaster;
    const canSendOtherFiles = (organization && organization.features && organization.features.otherFiles) || this.props.store.currentUser.isMaster;

    if (accepted.length + attachmentsCount > ATTACHMENT_LIMIT) {
      accepted = accepted.splice(0, ATTACHMENT_LIMIT - attachmentsCount);
      store.snackbar = { active: true, message: __('You can not have more than %s attachments in a message, exceeding files will be ignored', ATTACHMENT_LIMIT), success: false, dismissAfter: 4000 };
    }

    if (accepted.length > 0) {
      let medias = this.state.medias;
      let index = medias.length - 1;

      const blacklist = [
        'application', 'gadget', 'msp', 'ade', 'adp', 'apk', 'appx', 'appxbundle', 'bat',
        'cab', 'chm', 'cmd', 'com', 'cpl', 'dll', 'dmg', 'exe', 'hta', 'ins', 'inf',
        'isp', 'iso', 'jar', 'js', 'jse', 'lib', 'lnk', 'mde', 'msc', 'msi', 'msix',
        'msixbundle', 'msp', 'mst', 'nsh', 'msh', 'msh1', 'msh2', 'mshxml', 'msh2xml',
        'msh1xml', 'pif', 'ps1', 'ps2', 'ps1xml', 'ps2xml', 'psc1', 'psc2', 'reg', 'scr',
        'sct', 'shb', 'sys', 'vb', 'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh', 'html', 'htm'
      ];

      let hasNotAcceptedAudios = false;
      let hasNotAcceptedFiles = false;
      let hasNotAcceptedVideos = false;

      accepted = accepted.filter((file) => {
        if (!canSendAudio && utils.isAudioFile(file.type, file.name)) {
          if (utils.isEntityType(store, 'STUDENT')) {
            store.snackbar = { active: true, message: __('Your current plan does not allow the upload of the selected file type.'), success: false, dismissAfter: 4000 };
          } else {
            hasNotAcceptedAudios = true;
          }
          return false;
        }

        if (!canSendVideos && utils.isVideoFile(file.type, file.name)) {
          if (utils.isEntityType(store, 'STUDENT')) {
            store.snackbar = { active: true, message: __('Your current plan does not allow the upload of the selected file type.'), success: false, dismissAfter: 4000 };
          } else {
            hasNotAcceptedVideos = true;
          }
          return false;
        }

        if (blacklist.some(extension => file.name.endsWith('.' + extension))) {
          store.snackbar = { active: true, message: __('This file type is not allowed'), success: false, dismissAfter: 4000 };
          return false;
        }

        const isImage = utils.isImageFile(file.type);
        const isVideo = utils.isVideoFile(file.type, file.name);
        const isPdf = utils.isPdfFile(file.name);

        if (!canSendOtherFiles && !isPdf && !isImage && !isVideo) {
          rejected.push(file);
          if (utils.isEntityType(store, 'STUDENT')) {
            store.snackbar = { active: true, message: __('Your current plan does not allow the upload of the selected file type.'), success: false, dismissAfter: 4000 };
          } else {
            hasNotAcceptedFiles = true;
          }
          return false;
        }

        return true;
      });

      if (hasNotAcceptedAudios) this.openPaywall('AUDIOS');
      if (hasNotAcceptedVideos) this.openPaywall('VIDEO_MESSAGE');
      if (hasNotAcceptedFiles) this.openPaywall('MORE_FILES');

      await accepted.forEach((file) => {
        file.loading = true;
        file.uniqueId = uniqueId();

        index += 1;
        medias = [...medias, file];

        this.setState({ medias });
        this.uploadFile(file, index);
      });

      this.setWritingReply();
    }
  }

  onUploadProgress = (progressEvent, index) => {
    const { loaded, total } = progressEvent;
    const medias = this.state.medias;
    medias[index].progress = Math.round((loaded * 100) / total);
    this.setState({ medias });
  }

  onCancelEdited = (content, medias) => {
    const hasContent = content || medias;

    this.props.store.appends.push(<Modal
      size="tiny"
      onClose={() => this.props.store.appends.pop()}
      header={__('Discard message', this.props.object)}
      content={hasContent &&
        __('Are you sure you want to discard this reply?')}
      actions={[
        <Button
          data-action="cancel"
          key={0}
          basic
          floated="left"
          content={__('Cancel')}
          onClick={() => this.props.store.appends.pop()}
        />,
        <Button
          data-action="discard-changes"
          key={2}
          negative
          content={__('Discard Changes')}
          onClick={() => {
            this.setWritingReply(false);
            this.props.onCancel();
            this.props.store.appends.pop();
            this.props.store.snackbar = { active: true, message: __('Changes discarded'), success: true };
          }}
        />
      ]}
    />);
  }

  setWritingReply = (isWritingReply = null) => {
    if (!this.props.setWritingReply) return;

    if (isWritingReply !== null) {
      this.props.setWritingReply(isWritingReply);
      return;
    }

    this.props.setWritingReply(this.state.content.trim().length > 0 || this.state.medias.length > 0);
  }

  uploadErrorModal = (errorMessage) => {
    /*
    TODO: Modal abre mas não está fechando ao clicar em "Close"

    const anotherModalIsOpen = !!this.props.store.appends.find(e => (e.props.id === 'uploadErrorModal'));
    if (!anotherModalIsOpen) {
      this.props.store.appends.push(<Modal
        id="uploadErrorModal"
        size="tiny"
        onClose={() => this.props.store.appends.pop()}
        header={__('Upload Error')}
        content={errorMessage}
        actions={[
          <Button
            data-action="close"
            key={0}
            basic
            content={__('Close')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
      />);
    }
    */
  }

  uploadFile = (file, index) => this.props.api.upload('media', {
    file,
    query: uploadMediaMutation,
    variables: `{"entityId": ${this.props.store.currentEntity.id}}`
  }, progressEvent => this.onUploadProgress(progressEvent, index))
    .then((data) => {
      file.loading = false;

      if (data.errors) throw new Error(data.errors[0].message);
      file.response = data.data.uploadMedia.media;

      const medias = this.state.medias;
      medias[index] = file;
      this.setState({ medias });
    }).catch((error) => {
      file.loading = false;
      file.failed = true;

      const medias = this.state.medias;
      medias.splice(index, 1);

      switch (error.message) {
        case 'This file type is not allowed':
          this.uploadErrorModal(__('This file type is not allowed'));
          break;
        case 'Organization has no permission to send audios':
          this.uploadErrorModal(__('Organization has no permission to send audios'));
          break;
        case 'Organization has no permission to send videos':
          this.uploadErrorModal(__('Organization has no permission to send videos'));
          break;
        case 'Organization has no permission to send files':
          this.uploadErrorModal(__('Organization has no permission to send files, except PDF'));
          break;
        default:
          this.uploadErrorModal(__("Some files couldn't be attached. Please check your internet connection and try again"));
      }

      this.setState({ medias });
    });

  hasModifications() {
    const { medias, content } = this.state;
    const { reply } = this.props;

    return (
      content !== (reply && reply.content) ||
      !isEqual(
        medias && medias.map(m => (m.uniqueId ? `uid:${m.uniqueId}` : `name:${m.name}`)),
        reply && reply.medias && reply.medias.nodes && reply.medias.nodes.map(m => `name:${m.origName}`)
      )
    );
  }

  request = (contentValue, medias) => {
    const { store, reply, updateReply } = this.props;
    const request = { mutate: updateReply, input: 'updateReplyInput', message: __('Reply edited successfully!') };
    this.setState({ loading: true });
    this.setWritingReply(false);

    const content = contentValue.replace(/<(?!a)(?!\/a>)/gi, '&lt;');

    const props = {
      id: reply && reply.id,
      status: reply && reply.statusText,
      content,
      medias: medias.filter(media => media.response).map(media => ({
        filename: media.response.filename,
        type: utils.getMediaType(media.type, media.response.filename)
      }))
    };

    return request.mutate({
      variables: {
        [request.input]: {
          ...props
        }
      }
    }).then(() => {
      this.props.onSubmit({ ...props, medias: medias.filter(media => media.response) });
      this.setState({ loading: false });
      store.snackbar = { active: true, message: request.message, success: true, dismissAfter: 5000 };
    }).catch((err) => {
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false, dismissAfter: 5000 };
    });
  }

  renderMediaPreview = media => this.props.store.appends.push(<Modal
    size="small"
    closeIcon="times close"
    onClose={() => this.props.store.appends.pop()}
  >
    <Modal.Content image>
      <Container textAlign="center">
        {[
          media.type.includes('image') &&
          <Image key="image" src={media.preview} wrapped />,

          utils.isAudioFile(media.type, media.name) &&
          <AudioPlayer key="audio" url={media.response.uri} name={media.name} />,

          utils.isVideoFile(media.type, media.name) &&
          <div key="video" className="clsp-video" style={{ height: 320 }}>
            <VideoPlayer src={media.response.uri} name={media.name} thumbnail={media.thumbnail} />
          </div>
        ]}
      </Container>
    </Modal.Content>
  </Modal>);

  renderMedias = () => (this.state.medias || this.props.medias).map((media, key) => (
    <AttachmentPreview
      key={key}
      media={media}
      insideReply
      isMobile={this.isMobile()}
      onDelete={() => {
        const medias = this.state.medias;
        medias.splice(key, 1);
        this.setState({ medias }, () => {
          this.setWritingReply();
        });
      }}
      onPreview={() => this.renderMediaPreview(media)}
    />
  ));

  renderMediaButton = () => (
    <Button
      data-action="attach"
      basic
      circular
      as={Dropzone}
      style={{ margin: 0, boxShadow: 'none', padding: '12px 8px' }}
      ref={(node) => { this.dropzone = node; }}
      onDrop={(accepted, rejected) => {
        const attachmentsCount = this.state.medias.length;

        if (attachmentsCount + 1 > ATTACHMENT_LIMIT) {
          this.props.store.snackbar = { active: true, message: __('You can not have more than %s attachments in a message, all files will be ignored', ATTACHMENT_LIMIT), success: false, dismissAfter: 4000 };
          return;
        }

        this.onFileDrop(accepted, rejected);
      }}
      multiple
      maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
      icon="paperclip"
    />
  )

  renderSubmitButton = () => {
    const { content, medias, loading } = this.state;

    return (<Button
      data-action="submit"
      disabled={!(content.trim().length || medias.length) || loading || !!medias.filter(media => media.loading).length}
      loading={loading || !!medias.filter(media => media.loading).length}
      style={{ margin: 0, padding: '12px 8px', color: '#0080ff', backgroundColor: 'inherit' }}
      onClick={this.onSubmit}
      content={__('Send')}
    />);
  }

  renderSubmitButtonForEditReply = () => {
    const { editReply } = this.props;
    const { content, medias, loading } = this.state;

    if (!editReply) {
      return null;
    }

    return (
      <div style={{ display: 'flex' }}>
        <Button
          data-action="submit"
          style={{ margin: 0, padding: '12px 8px', color: '#666666', backgroundColor: 'inherit' }}
          onClick={() => this.onCancelEdited(content, medias)}
          content={__('Cancel')}
        />
        <Button
          data-action="submit"
          disabled={!(content.trim().length || medias.length) || !!medias.filter(media => media.loading).length || !this.hasModifications()}
          loading={!!medias.filter(media => media.loading).length || loading}
          style={{ margin: 0, padding: '12px 8px', color: '#0080ff', backgroundColor: 'inherit' }}
          onClick={() => this.request(content, medias)}
          content={__('Save')}
        />
      </div>
    );
  }

  render() {
    const { store, replyRecipient, attached, editReply, reply } = this.props;
    const entity = reply ? reply.entity : store.currentEntity;
    const organization = store.entity ? store.entity.organization : store.currentOrganization;

    if (replyRecipient && replyRecipient.disabled) {
      return (
        <Segment attached={attached} tertiary style={{ padding: '12px' }}>
          <div id="ReplyForm">
            {__('%s was disabled and cannot receive new messages. To find out more, contact the organization.', replyRecipient.fullname)}
          </div>
        </Segment>
      );
    }

    return (
      <Segment attached={attached} style={{ padding: '12px' }}>
        <div id="ReplyForm">
          {
            this.state.medias.length ?
              <div style={{ display: 'flex', marginBottom: '24px', overflowX: 'auto' }}>
                {this.renderMedias()}
              </div>
              :
              null
          }
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', paddingBottom: '4px' }}>
              <Popup
                trigger={<Avatar avatar src={entity.picture && entity.picture.uri} alt={entity && entity.fullname} />}
                content={<span>{entity && entity.fullname}</span>}
              />
            </div>
            <Form style={{ width: '85%' }}>
              <TextareaAutosize
                style={{ border: 'none', minHeight: '39px' }}
                maxRows={15}
                placeholder={__('Reply') + '...'}
                name="content"
                autoHeight
                autoFocus={this.props.autoFocus || null}
                onFocus={this.props.onFocus || null}
                value={this.state.content}
                onChange={e => this.setState({ content: e.target.value }, () => this.setWritingReply())}
              />
            </Form>
            <div style={{ display: 'flex' }}>
              {
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  {
                    (!organization || ((!!organization.confMediaStudent || entity.type !== 'STUDENT') && !!organization.confMedia && organization.features.files)) &&
                    this.renderMediaButton()
                  }
                  {
                    editReply ?
                      this.renderSubmitButtonForEditReply()
                      :
                      this.renderSubmitButton()
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </Segment>
    );
  }
}
