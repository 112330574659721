import React from 'react';
import { Link } from 'react-router';

import { inject, observer } from 'mobx-react';
import gql from 'graphql-tag';

import { Segment, Icon, Popup, Item, Dropdown, Label, Loader, Dimmer } from 'semantic-ui-react';
import { filter } from 'graphql-anywhere';
import VideoThumbnail from 'react-video-thumbnail';
import moment from 'moment';
import { uniqueId } from 'lodash';

import Avatar from '../../components/Avatar';
import AudioPlayer from '../../components/AudioPlayer';
import Responsive from '../../components/Responsive';

import MediaItem from '../Media/Item';
import ReplyEdit from '../Reply/Edit';
import ReplyHistory from '../Reply/History';

import * as utils from '../../utils';
import { __ } from '../../i18n';
import { client_id } from '../../api';

const replyHistoryFragments = {
  replyHistory: gql`
  fragment ReplyItemHistoryFragment on Reply {
    id: key
    status
    content
    created
    modified
    deleted
    statusText
    entity {
      id: dbId
      fullname
      disabled
      organizationId
      picture {
        id: dbId
        key
        filename
        uri
      }
      roles (limit: 10) {
        nodes {
          id: dbId
          key
          name
        }
      }
    }
    user {
      id:dbId
      fullname
    }
    medias {
      nodes {
        id: dbId
        type
        filename
        mimetype
        origName
        original: uri(size:"w1280")
        thumbnail(size:"w320")
        uri
      }
    }
  }`
};

@inject('store')
@inject('client')
@observer
export default class ReplyItem extends Responsive {
  static fragments = {
    reply: gql`
      fragment ReplyItem on Reply {
        id: dbId
        status
        content
        read
        created
        modified
        deleted
        statusText
        logs {
          nodes {
            id: dbId
            event
            reference
            currentValue
            previousValue
            referenceId
            created
            version
            media {
              id: dbId
              type
              filename
              origName
              uri
              type
              mimetype
            }
          }
          totalCount
        }
        entity {
          id:dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
          roles {
            totalCount
          }
          organizationId
          type
        }
        user {
          id:dbId
          fullname
        }
        images: medias (type: IMAGE) {
          nodes {
            id: dbId
            uri (size: "w640")
            ...MediaItemImage
          }
        }
        files: medias (type: FILE) {
          nodes {
            id: dbId
            origName
            uri
            mimetype
          }
        }
        videos: medias (type: VIDEO) {
          nodes {
            id: dbId
            ...MediaItemVideo
          }
        }
        audios: medias (type: AUDIO) {
          nodes {
            id: dbId
            ...MediaItemAudio
          }
        }
        replyHistory {
          ...ReplyItemHistoryFragment
        }
        ...ReplyHistory
      }
      ${MediaItem.fragments.image}
      ${MediaItem.fragments.video}
      ${MediaItem.fragments.audio}
      ${replyHistoryFragments.replyHistory}
      ${ReplyHistory.fragments.medias}
      `,
  }

  constructor(props) {
    super(props);
    this.state = {
      see_all: false,
      editReply: false,
      content: (props.reply && props.reply.content) || '',
      medias: (props.reply && props.reply.medias && props.reply.medias.nodes.map(media => ({
        name: media.origName || uniqueId(),
        type: media.type.toLowerCase(),
        preview: media.uri,
        thumbnail: media.type.toLowerCase().includes('video') ? media.thumbnail || `https://images.classapp.com.br/w320/classapp-${props.store.app.env === 'production' ? 'live' : props.store.app.env}-media-1/thumb-${media.filename}-00001.png` : null,
        response: { filename: media.filename, uri: media.uri }
      }))) || [],
      groupsButton: false,
      loadingGroups: false,
      groupsEntity: [],
      totalGroups: 0,
    };
  }

  componentDidUpdate() {
    const { content } = this.state;
    if (content !== this.props.reply.content) {
      this.setState({ content: this.props.reply.content });
    }
  }

  attachedItem = (key, length, canReply) => {
    if (key === 0 && length === 1 && !canReply) return false;
    else if (key === 0) return 'top';
    else if (key === length - 1 && !canReply) return 'bottom';
    return true;
  }

  onSubmitEditing = (values) => {
    const { store } = this.props;
    let newMedias = [];
    let newContent = '';

    newContent = values && values.content;

    newMedias = values && values.medias && values.medias.map(media => ({
      name: media.name,
      type: media.type.toLowerCase(),
      preview: media.preview || media.response.uri,
      thumbnail: media.type.toLowerCase().includes('video') ? media.thumbnail || `https://images.classapp.com.br/w320/classapp-${store.app.env === 'production' ? 'live' : store.app.env}-media-1/thumb-${media.response.filename}-00001.png` : null,
      response: media.response
    }));

    this.setState({ editReply: false, content: newContent, medias: newMedias });
  }

  onCancelEditing = () => {
    this.setState({ editReply: false });
  }

  renderMedias = () => this.props.reply.images.nodes.map(image => <div
    data-action="open-media-item"
    key={image.id}
    style={{
      height: '130px',
      width: '160px',
      marginRight: '8px',
      background: 'url(' + image.uri + ')',
      backgroundSize: ' cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      justifyContent: 'center',
      borderRadius: '4px'
    }}
    className="pointer"
    onClick={(e) => {
      e.preventDefault();
      this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.image, image)} />);
    }}
  />).concat(this.props.reply.videos.nodes.map(video => (
    <div
      data-action="open-media-item"
      key={video.id}
      onClick={(e) => {
        e.preventDefault();
        this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.video, video)} />);
      }}
      className="pointer"
      style={{
        backgroundImage: 'url(' + video.thumbnail + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '130px',
        width: '160px',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >

      {!video.thumbnail &&
        <div className="circular" style={{ position: 'absolute', width: '160px', height: '130px' }}>
          <VideoThumbnail
            videoUrl={video.original}
            snapshotAtTime={1}
          />
        </div>
      }

      {/* <Icon name="play circle" size="huge" style={{ backgroundColor: '#000000FF', color: '#FFFFFF', width: 75, height: 75, borderRadius: 6, justifyContent: 'center' }} /> */}
      <span className="rh5v-Overlay_inner" style={{ margin: '-65px 0px 0px -155px', position: 'relative' }}><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" className="rh5v-Overlay_icon" fill="#fff"><path d="M8.016 5.016L18.985 12 8.016 18.984V5.015z" /></svg></span>
    </div>

  ))).concat(this.props.reply.files.nodes.map(file => <Segment
    style={{
      height: '130px',
      width: '160px',
      marginRight: '8px',
      borderRadius: '4px',
      display: 'table',
      textAlign: 'center',
      backgroundColor: '#f3f4f5',
      marginTop: 0
    }}
    as="a"
    target="_blank"
    href={`/download_file?name=${encodeURIComponent(file.origName)}&path=${encodeURIComponent(file.uri)}`}
    className="wordWrapped"
  >
    <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>
      <Icon name={utils.getAttachmentIcon(file.mimetype, file.origName)} style={{ fontSize: 30 }} />
      {
        file.origName.length > 36 ?
          <Popup
            trigger={<p>{file.origName.replace(/^(.{36}).+/, '$1...')}</p>}
            content={<span>{file.origName}</span>}
          />
          :
          <p>{file.origName}</p>
      }
    </span>
  </Segment>))

  async renderGroups() {
    this.setState({ loadingGroups: true, groupsButton: !this.state.groupsButton });
    const { entity } = this.props.reply;
    const { client } = this.props;

    try {
      const data = await client.query({
        query: gql`
        query groups{
          node(id: ${this.props.messageId}){
            ...on Message{
              id
              myConversations(entityId: ${entity.id}, showDeletedReplies: true){
                nodes{
                  entity{
                    id
                    dbId
                    roles{
                      nodes{
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
      });

      if (data && !data.loading) {
        const groupsEntity = data.data.node.myConversations.nodes.find(node => node.entity.dbId === entity.id).entity.roles.nodes;
        this.setState({ loadingGroups: false, groupsEntity, totalGroups: groupsEntity.length });
      }
    } catch (error) {
      return console.log(error);
    }
  }

  render() {
    const { reply, entity_id, store, index, length, canReply, messageEntity, messageId, params, conversation } = this.props;
    const { see_all, editReply, medias } = this.state;
    const { content, id, entity, user, status, read, created, images, files, videos, audios } = reply;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const isNew = !(entity.id.toString() === entity_id) && status < 2;
    const linkProps = {};
    const totalGroups = entity.roles.totalCount;
    const moreGroups = totalGroups - 4;
    const fromOrganization = store.currentLocation.pathname.includes('/organizations/');
    const canSeeOrganizationGroups = fromOrganization && (store.currentEntity.type === 'ADMIN' || store.currentUser.isMaster || store.currentEntity.organization.permissions.entityScopes.includes('READ_GROUP') ||
      store.currentEntity.organization.permissions.hasAdmin);
    const hasReplyApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_reply; //Unleash implementation

    if (store.currentUser.isMaster || (store.currentEntity.type === 'ADMIN' && store.currentEntity.organization.id === messageEntity.organizationId)) {
      linkProps.as = Link;
      linkProps.to = `/organizations/${entity.organizationId}/entities?search=${entity.fullname}`;
    }

    const baseLink = !params.organization_id ?
      `/entities/${store.currentEntity.id}` : `/organizations/${params.organization_id}`;

    if (editReply) return <ReplyEdit params={params} content={this.state.content} medias={[...medias]} canReply editReply reply={reply} conversation={conversation} onSubmit={this.onSubmitEditing} onCancel={this.onCancelEditing} attached={this.attachedItem(index, length, canReply)} />;

    const moreOptions = [];

    const isReplyOwner = store.currentUser.id === reply.user.id && (store.currentEntity.id === reply.entity.id || params.organization_id);

    const hasRightsOverReply = store.currentEntity.type === 'ADMIN' && reply.entity.type !== 'STUDENT' && (params.organization_id || (reply.entity.id === store.entity.id));

    const { features } = store.currentOrganization || {};

    if (((features && features.editMessages) || store.currentUser.isMaster) && !reply.deleted && (isReplyOwner || hasRightsOverReply)) {
      moreOptions.push(
        <Dropdown.Item
          data-action="update-reply"
          data-params="edit"
          text={__('Edit reply')}
          icon="edit"
          onClick={() => this.setState({ editReply: true })}
        />
      );
    }

    if ((params.organization_id || (reply.entity.id === store.entity.id) || store.currentEntity.type === 'ADMIN') && !reply.deleted && reply.logs.totalCount > 0) {
      moreOptions.push(
        <Dropdown.Item
          data-action="open-edit-history"
          data-params="history"
          text={__('View edit history')}
          icon="history"
          onClick={() => this.props.store.appends.push(<ReplyHistory reply={reply} />)}
        />
      );
    }

    if (((features && features.deleteMessages) || store.currentUser.isMaster) && !reply.deleted && (isReplyOwner || hasRightsOverReply) && (moment(reply && reply.created) >= moment().subtract(1, 'hour'))) {
      moreOptions.push(
        <Dropdown.Item
          as={Link}
          data-action="update-status"
          data-params="delete"
          text={__('Delete for everyone')}
          icon="trash alt"
          to={`${baseLink}/messages/${messageId}/deleteReply?deleteForEveryone=true&replyId=${reply.id}`}
        />
      );
    }

    return (
      <Segment
        className="replyItem"
        title={reply.deleted ? __('This reply was deleted') : undefined}
        data-content={reply.deleted ? undefined : content}
        data-id={id}
        attached={this.attachedItem(index, length, canReply)}
        style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 14px' }}
        onClick={() => this.setState({ see_all: true })}
      >
        <div style={{ display: 'flex', width: 'calc(100% + 2px)', opacity: reply && reply.deleted ? 0.65 : null, fontStyle: reply && reply.deleted ? 'italic' : null }}>
          <Popup
            trigger={<Avatar avatar svgStyle={this.isMobile() ? { height: 'unset', width: '100%' } : null} style={this.isMobile() ? { height: '32px', width: '32px' } : null} src={entity.picture && entity.picture.uri} alt={entity.fullname} />}
            content={<span>{user.fullname}</span>}
          />
          <div style={{ margin: 'auto 14px', marginRight: '0px', width: this.isMobile() ? 'calc(75%)' : 'calc(80%)' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Item {...linkProps}>
                  <span style={{ fontWeight: 'bold', fontSize: '16px', color: '#0080ff'}}>{entity.fullname || __('Deleted Person')}</span>
                  {
                    user.id === store.currentUser.id ?
                    <span className='fullname-container' style={{backgroundColor: '#ededed', paddingRight: '6px', fontStyle: 'italic'}}>
                      {__("You")}
                    </span>
                    :
                    <span className='fullname-container' style={{borderColor: '#d6d6d6', borderWidth: 1, borderStyle: 'solid'}}>
                      <Icon name='user' size='small' color='grey' />
                      {user.fullname || __('Deleted Person')}
                    </span>
                  }
                </Item>
                <div style={{ whiteSpace: this.isMobile() ? 'none' : 'initial', color: '#9696a0', fontSize: '12px', marginTop: '5px' }}>
                  <span>{utils.simpleDate(reply && reply.logs && (reply.logs.totalCount > 0) ? reply.logs.nodes[0].created : created, true, 'LLL', lang)}</span>
                  {
                    reply && reply.deleted ?

                      <span> · {__('deleted')} </span>
                      :
                      (
                        reply && reply.logs && (reply.logs.totalCount > 0) ?
                          <span> · {__('edited')} </span>
                          :
                          ''
                      )
                  }
                  {
                    entity.id.toString() === entity_id && !reply.deleted ?
                      (
                        status === null ?
                          <Icon name="clock" /> :
                          (
                            read === null ?
                              <Icon name="check" style={{ paddingRight: '5px', marginLeft: '5px' }} />
                              :
                              <Icon name="check double" style={{ paddingRight: '5px', marginLeft: '5px', color: '#0080FF' }} />
                          )
                      ) :
                      (status < 2 && !reply.deleted ? <Icon size="small" name="circle" className="bold" color="blue" style={{ marginLeft: '5px' }} /> : null)
                  }
                </div>
              </div>
              {this.isMobile() &&
                <div style={{ display: 'flex' }}>
                  {(fromOrganization && canSeeOrganizationGroups && totalGroups > 0 && store.currentEntity.type !== 'STUDENT') ||
                (!fromOrganization && totalGroups > 0 && store.currentEntity.type !== 'STUDENT') && !reply.deleted ?


                  <Icon name={this.state.groupsButton ? 'angle right' : 'angle down'} style={{ display: 'inline-block', marginRight: '12px', cursor: 'pointer' }} onClick={() => this.renderGroups()} />
                    : null}
                  {
                    moreOptions.length > 0 &&
                (
                  <div style={{ paddingLeft: this.isMobile() ? '5px' : '18px' }}>
                    <Dropdown data-action="open-options" data-params="more" icon="ellipsis h">
                      <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
                        {moreOptions}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
                  }
                </div>
              }
            </div>
            <div
              style={{ display: this.state.groupsButton ? 'inline' : 'none' }}
            >
              <Segment style={{ padding: 0, border: 0, margin: 0 }}>
                <Dimmer active={this.state.loadingGroups} style={{ border: 0 }} inverted>
                  <Loader size="mini" />
                </Dimmer>
                {this.state.groupsEntity.map((group, index) => (index <= 3 ?
                  <Label key={group.id} basic style={{ marginTop: '8px', marginRight: '6px', fontSize: 11 }}> {group.name} </Label>
                  : null)
                )}


                {this.state.totalGroups > 4 ?
                  <Popup
                    trigger={<Label basic style={{ marginTop: '8px', marginLeft: '6px', fontSize: 11, cursor: 'pointer' }}> {` + ${this.state.totalGroups - 4}`} </Label>}
                    content={this.state.groupsEntity.map((group_1, index_1) => (index_1 > 3 ? (
                      <Label key={group_1.id} basic style={{ marginTop: '8px', marginLeft: '6px', fontSize: 11 }}> {group_1.name} </Label>
                    )
                      : null)
                    )}
                  />
                  : null}
              </Segment>
            </div>
            {
              (content && content.length > 140) &&
                !images.nodes.length && !files.nodes.length && !videos.nodes.length && !audios.nodes.length && !reply.deleted ?
                <div
                    className="wordWrapped"
                    style={{
                    whiteSpace: 'pre-wrap',
                    fontWeight: isNew ? 'bold' : undefined,
                    opacity: reply && reply.deleted ? 0.65 : null,
                    fontStyle: reply && reply.deleted ? 'italic' : null,
                    margin: '10px 0'
                  }}
                  >
                    {
                    see_all ?
                      <span dangerouslySetInnerHTML={{ __html: utils.parseText(content) }} />
                      :
                      [
                        <span key={1}>{content.slice(0, 140) + '...'}</span>,
                        <a key={2}> {__('See more')}</a>
                      ]
                  }
                  </div>
                :
                  <div
                  className="wordWrapped"
                  style={{
                    whiteSpace: 'pre-wrap',
                    fontWeight: isNew ? 'bold' : undefined,
                    margin: '10px 0'
                  }}
                >
                  <span
                      dangerouslySetInnerHTML={{
                      __html: reply.deleted && !params.organization_id ? __('This reply was deleted') : utils.parseText(content)
                    }}
                    />
                </div>
            }
            {
              (!reply.deleted || params.organization_id) ?
                audios.nodes.map((audio, i) => {
                  const removeExtensionFromAudio = audio.origName.replace(/\.\w*$/, '');
                  return (<AudioPlayer small={this.isMobile()} key={i} url={audio.uri} alias={removeExtensionFromAudio} name={audio.origName} />);
                })
                : ''
            }
            {
              (!reply.deleted || params.organization_id) &&
                (images.nodes.length || files.nodes.length || videos.nodes.length || audios.nodes.length) ?
                <div style={{ display: '-webkit-box', overflowX: 'auto', margin: '12px 0px', opacity: reply && reply.deleted ? 0.65 : null }}>
                    {this.renderMedias()}
                  </div>
                :
                null
            }
          </div>

          {!this.isMobile() && ((fromOrganization && canSeeOrganizationGroups && totalGroups > 0 && store.currentEntity.type !== 'STUDENT') ||
            (!fromOrganization && totalGroups > 0 && store.currentEntity.type !== 'STUDENT') && !reply.deleted) ?

            <Icon name={this.state.groupsButton ? 'angle right' : 'angle down'} style={{ display: 'inline-block', marginRight: '12px', cursor: 'pointer' }} onClick={() => this.renderGroups()} />
            : null
          }
          {
            !this.isMobile() && moreOptions.length > 0 &&
            (
              <div style={{ paddingLeft: this.isMobile() ? '5px' : '18px' }}>
                <Dropdown data-action="open-options" data-params="more" icon="ellipsis h">
                  <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
                    {moreOptions}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )
          }
        </div>
      </Segment>
    );
  }
}
