import React from 'react';
import { inject, observer } from 'mobx-react';

import ReplyForm from './Form';

@inject('store') @observer
export default class ReplyEdit extends React.Component {
  render() {
    const { store, editReply, reply, canReply, conversation, medias, content, attached, onSubmit, onCancel } = this.props;
    const { nodes } = conversation;

    return (
      canReply ?
        <ReplyForm
          reply={reply}
          replyRecipient={reply.statusText === 'RECEIVED' ? reply.entity : nodes[0].parent}
          content={content}
          medias={medias}
          store={store}
          editReply={editReply}
          onSubmit={onSubmit}
          onCancel={onCancel}
          attached={attached}
          autoFocus
          onFocus={function (e) {
            const val = e.target.value;
            e.target.value = '';
            e.target.value = val;
          }}
        />
        :
        null
    );
  }
}
