import React from 'react';
import { Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Pagination from '../../components/Pagination';
import Page from '../../components/Page';

import ReplyList from './List';

const PAGE_SIZE = 10;

@graphql(gql`query ReplyGroupEntityQuery (
  $entityId: ID!,
  $messageId: ID!,
  $offset: Int,
  $limit: Int
) {
  node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
    ... on Entity {
      id: dbId
      message (id: $messageId) {
        id: dbId
        conversations (offset: $offset, limit: $limit, showDeletedReplies: true) {
          totalCount
          nodes {
            parentId
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id,
      offset: 0,
      limit: PAGE_SIZE
    }
  }),
  skip: ownProps => ownProps.params.organization_id
})
@graphql(gql`query ReplyGroupOrganizationQuery (
  $messageId: ID!,
  $offset: Int,
  $limit: Int,
  $showDeletedReplies: Boolean
) {
  node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
    ... on Message {
      id: dbId
      conversations (offset: $offset, limit: $limit, showDeletedReplies: $showDeletedReplies) {
        totalCount
        nodes {
          parentId
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      messageId: ownProps.params.message_id,
      showDeletedReplies: ownProps.showDeletedReplies,
      offset: 0,
      limit: PAGE_SIZE
    }
  }),
  skip: ownProps => !ownProps.params.organization_id
})
export default class ReplyGroup extends Page {
  render() {
    const { data, params, location, showDeletedReplies } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    let { message } = data.node;

    if (params.organization_id) {
      message = data.node;
    }

    const { conversations } = message;
    const { nodes, pageInfo } = conversations;

    return (
      <div>
        {
          nodes.map((conversation) => {
            if (!params.organization_id) {
              return (<ReplyList
                key={conversation.parentId}
                parent_id={conversation.parentId}
                entity_id={params.entity_id}
                message_id={params.message_id}
                location={location}
                params={params}
              />);
            }

            return (<ReplyList
              key={conversation.parentId}
              parent_id={conversation.parentId}
              entity_id={params.entity_id}
              organization_id={params.organization_id}
              message_id={params.message_id}
              params={params}
              showDeletedReplies={showDeletedReplies}
            />);
          })
        }
        <Pagination
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => {
            if (params.organization_id) return this.loadMore('conversations');
            return this.loadMoreWithMoreEdges('message', 'conversations');
          }}
        />
      </div>
    );
  }
}
