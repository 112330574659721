import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Segment, Icon, Popup, Item } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import VideoThumbnail from 'react-video-thumbnail';

import Avatar from '../../components/Avatar';
import MediaItem from '../Media/Item';
import History from '../../components/History';

import * as utils from '../../utils';
import { __ } from '../../i18n';

@inject('store') @observer
export default class ReplyHistory extends React.Component {
  static fragments = {
    medias: gql`
      fragment ReplyHistory on Reply {
        medias {
          nodes {
            id: dbId
            type
            original: uri(size:"w1280")
            filename
            origName
            mimetype
            uri
            type
            thumbnail(size:"w320")
          }
        }
      }
    `,
  }

  attachedItem = (key, length, canReply) => {
    if (key === 0 && length === 1 && !canReply) return false;
    else if (key === 0) return 'top';
    else if (key === length - 1 && !canReply) return 'bottom';
    return true;
  }

  renderMedias = (medias) => {
    const renderedMedias = [];
    medias.nodes.map((media) => {
      if (media.type === 'IMAGE') {
        renderedMedias.push(<div
          data-action="open-media-item"
          key={media.id}
          style={{
            height: '130px',
            width: '160px',
            marginRight: '8px',
            background: 'url(' + media.uri + ')',
            backgroundSize: ' cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            justifyContent: 'center',
            borderRadius: '4px'
          }}
          className="pointer"
          onClick={(e) => {
            e.preventDefault();
            this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.image, media)} />);
          }}
          as="a"
        />);
      } else if (media.type === 'VIDEO') {
        renderedMedias.push(
          <div
            data-action="open-media-item"
            key={media.id}
            onClick={(e) => {
              e.preventDefault();
              this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.video, media)} />);
            }}
            className="pointer"
            style={{
              backgroundImage: 'url(' + media.thumbnail + ')',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              height: '130px',
              width: '160px',
              margin: '0px 4px 33px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            as="a"
          >

            { !media.thumbnail &&
              <div style={{ position: 'absolute', width: '160px', height: '130px' }}>
                <VideoThumbnail
                  videoUrl={media.original}
                  snapshotAtTime={1}
                />
              </div>
            }

            <span className="rh5v-Overlay_inner" style={{ margin: '-65px 0px 0px -155px', position: 'relative' }}><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" className="rh5v-Overlay_icon" fill="#fff"><path d="M8.016 5.016L18.985 12 8.016 18.984V5.015z" /></svg></span>
          </div>
        );
      } else if (media.type === 'AUDIO') {
        renderedMedias.push(<Segment
          style={{
            height: '130px',
            width: '160px',
            marginRight: '8px',
            borderRadius: '4px',
            display: 'table',
            textAlign: 'center',
            backgroundColor: '#f3f4f5',
            marginTop: 10
          }}
          onClick={(e) => {
            e.preventDefault();
            this.props.store.appends.push(<MediaItem media={filter(MediaItem.fragments.audio, media)} />);
          }}
          className="wordWrapped pointer"
          as="a"
        >
          <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Icon name={utils.getAttachmentIcon(media.mimetype, media.origName)} style={{ fontSize: 30 }} />
            {
              media.origName.length > 36 ?
                <Popup
                  trigger={<p>{media.origName.replace(/^(.{36}).+/, '$1...')}</p>}
                  content={<span>{media.origName}</span>}
                />
                :
                <p>{media.origName}</p>
            }
          </span>
        </Segment>);
      } else {
        renderedMedias.push(<Segment
          style={{
            height: '130px',
            width: '160px',
            marginRight: '8px',
            borderRadius: '4px',
            display: 'table',
            textAlign: 'center',
            backgroundColor: '#f3f4f5',
            marginTop: 10
          }}
          as="a"
          target="_blank"
          href={`/download_file?name=${encodeURIComponent(media.origName)}&path=${encodeURIComponent(media.uri)}`}
          className="wordWrapped"
        >
          <span style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            <Icon name={utils.getAttachmentIcon(media.mimetype, media.origName)} style={{ fontSize: 30 }} />
            {
              media.origName.length > 36 ?
                <Popup
                  trigger={<p>{media.origName.replace(/^(.{36}).+/, '$1...')}</p>}
                  content={<span>{media.origName}</span>}
                />
                :
                <p>{media.origName}</p>
            }
          </span>
        </Segment>);
      }
    });

    return renderedMedias;
  }

  renderRow = (item, key) => {
    const { store } = this.props;
    const { content, id, entity, user, medias, created, modified, logs } = item;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    const linkProps = {};

    if (store.currentUser.isMaster || (store.currentEntity.type === 'ADMIN' && store.currentEntity.organization.id === entity.organizationId)) {
      linkProps.as = Link;
      linkProps.to = `/organizations/${entity.organizationId}/entities?search=${entity.fullname}`;
    }

    return (
      <Segment
        key={key}
        className="replyItem"
        title={content}
        data-id={id}
        style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 14px' }}
      >
        <div style={{ display: 'flex', width: 'calc(100% + 2px)' }}>
          <Popup
            trigger={<Avatar avatar src={entity.picture && entity.picture.uri} alt={entity.fullname} />}
            content={<span>{user.fullname}</span>}
          />
          <div style={{ margin: 'auto 14px', width: 'calc(85%)' }}>
            <Item {...linkProps}>
              <span style={linkProps.to ? linkProps.to && { fontWeight: 'bold', color: '#007fff' } : { fontWeight: 'bold', color: '#007fff' }}>{entity.fullname || __('Deleted Person')}</span>
            </Item>
            <div style={{ whiteSpace: 'initial', color: '#9696a0', fontSize: '12px' }}>
              {user && <span>{user.fullname} · </span>}
              <span>{utils.simpleDate(!key ? logs && logs.nodes[0].created : created, true, 'LLL', lang)}</span>
            </div>
            {
              (medias && medias.nodes && medias.nodes.length) ?
                <div style={{ display: '-webkit-box', overflowX: 'auto', margin: '12px 0px' }}>
                  {this.renderMedias(medias, key)}
                </div>
                :
                null
            }
            <div className="wordWrapped" style={{ whiteSpace: 'pre-wrap' }}>
              <span
                dangerouslySetInnerHTML={{
                  __html: utils.parseText(content)
                }}
              />
            </div>
          </div>
        </div>
      </Segment>
    );
  }

  render() {
    const { reply } = this.props;

    const editedReplies = reply.replyHistory;
    const repliesForEditHistory = [reply].concat([...editedReplies]);

    return (
      <History
        id={'ReplyEditHistory'}
        header={__('Edit History')}
        renderRow={this.renderRow}
        source={repliesForEditHistory}
      />
    );
  }
}
