import React from 'react';
import { inject, observer } from 'mobx-react';
import { List, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import Pagination from '../../components/Pagination';
import Page from '../../components/Page';

import ReplyItem from '../Reply/Item';
import ReplyForm from '../Reply/Form';

const PAGE_SIZE = 10;

@inject('store')
@graphql(gql`query ReplyListEntityQuery (
  $messageId: ID!,
  $parentId: ID!,
  $entityId: ID!,
  $offset: Int,
  $limit: Int,
  $updateStatus: ReplyStatus,
  $deleteNotifications: Boolean
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId) {
          id: dbId
          noReply
          type
          entity {
            id: dbId
            organizationId
            disabled
            fullname
          }
          conversation (deleteNotifications: $deleteNotifications, updateStatus: $updateStatus, parentId: $parentId, entityId: $entityId, offset: $offset, last: $limit, orderBy:{ column: CREATED, direction: DESC}, showDeletedReplies: true) {
            totalCount
            nodes {
              parent {
                id: dbId
                disabled
                fullname
              }
              parentId
              entity {
                id: dbId
              }
              ...ReplyItem
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
          }
        }
      }
    }
  }
${ReplyItem.fragments.reply}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      messageId: ownProps.message_id,
      parentId: ownProps.parent_id,
      entityId: ownProps.entity_id,
      offset: 0,
      limit: PAGE_SIZE,
      updateStatus: ownProps.entity_id && ownProps.store.currentUser.entities &&
        (ownProps.store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === ownProps.entity_id).length > 0) ? 'READ' : null,
      deleteNotifications: ownProps.entity_id && ownProps.store.currentUser.entities &&
        (ownProps.store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === ownProps.entity_id).length > 0),
      showDeletedReplies: ownProps.showDeletedReplies
    }
  }),
  skip: ownProps => ownProps.organization_id
})
@graphql(gql`query ReplyListOrganizationQuery (
  $messageId: ID!,
  $parentId: ID!,
  $entityId: ID!,
  $offset: Int,
  $limit: Int,
  $updateStatus: ReplyStatus,
  $deleteNotifications: Boolean,
  $showDeletedReplies: Boolean
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        noReply
        type
        entity {
          id: dbId
          organizationId
          disabled
          fullname
        }
        conversation (deleteNotifications: $deleteNotifications, updateStatus: $updateStatus, parentId: $parentId, entityId: $entityId, offset: $offset, last: $limit, orderBy:{ column: CREATED, direction: DESC}, showDeletedReplies: $showDeletedReplies) {
          totalCount
          nodes {
            parent {
              id: dbId
              disabled
              fullname
            }
            parentId
            entity {
              id: dbId
            }
            ...ReplyItem
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
${ReplyItem.fragments.reply}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      messageId: ownProps.message_id,
      parentId: ownProps.parent_id,
      entityId: ownProps.entity_id,
      offset: 0,
      limit: PAGE_SIZE,
      updateStatus: ownProps.entity_id && ownProps.store.currentUser.entities &&
        (ownProps.store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === ownProps.entity_id).length > 0) ? 'READ' : null,
      deleteNotifications: ownProps.entity_id && ownProps.store.currentUser.entities &&
        (ownProps.store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === ownProps.entity_id).length > 0),
      showDeletedReplies: ownProps.showDeletedReplies,
    }
  }),
  skip: ownProps => !ownProps.organization_id
})
@observer
export default class ReplyList extends Page {
  render() {
    const { data, store, message_id, entity_id, parent_id, organization_id, location, params } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    let { message } = data.node;

    if (organization_id) {
      message = data.node;
    }

    const { conversation, noReply, type, entity } = message;
    const { nodes, pageInfo } = conversation;
    const canReply = !noReply && !organization_id && (store.currentEntity && !store.currentEntity.disabled) &&
      (store.currentEntity.type !== 'STUDENT' || (
        store.currentEntity.organization.confReplyStudent && (
          (store.currentEntity.organization && store.currentEntity.organization.confRepliesStudent) ||
          (!nodes.length && type === 'RECEIVED') || (nodes.length && (
            nodes[nodes.length - 1].entity.id !== store.currentEntity.id || nodes[nodes.length - 1].deleted
          ))
        )
      ));

    return (
      <List id="ReplyList" selection celled verticalAlign="middle">
        <Pagination
          pagination={{
            graphql: true,
            hasNextPage: pageInfo && pageInfo.hasNextPage,
            loading: data.loading
          }}
          onLoadMore={() => {
            if (organization_id) return this.loadMore('conversation', 'node', true);
            this.loadMoreWithMoreEdges('message', 'conversation', 'node', true);
          }}
        />
        {
          nodes.map((reply, key) => <ReplyItem
            index={key}
            length={nodes.length}
            reply={filter(ReplyItem.fragments.reply, reply)}
            entity_id={entity_id}
            canReply={canReply}
            messageEntity={entity}
            messageId={message_id}
            location={location}
            params={params}
            conversation={conversation}
          />)
        }
        {
          canReply &&
          <ReplyForm
            onSubmit={() => data.refetch()}
            message_id={message_id}
            entity_id={store.currentEntity.id}
            parent_id={parent_id}
            replyRecipient={type === 'RECEIVED' ? entity : nodes[0].parent}
            attached="bottom"
            setWritingReply={this.props.setWritingReply}
          />
        }
      </List>
    );
  }
}
