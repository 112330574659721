import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteReply($deleteReplyInput: DeleteReplyInput!) {
  deleteReply(input: $deleteReplyInput) {
    reply {
      id: dbId
    }
  }
}`, {
  name: 'deleteReply',
  options: {
    awaitRefetchQueries: true,
    refetchQueries: ['MessageRepliesEntityQuery', 'MessageRepliesOrganizationQuery', 'ReplyListEntityQuery', 'ReplyListOrganizationQuery']
  }
})

@observer
export default class ReplyDelete extends Controller {
  request = () => {
    const { deleteReply, store, location, router } = this.props;

    this.setState({ loading: true });

    return deleteReply({
      variables: {
        deleteReplyInput: {
          id: location.query.replyId
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('Reply was deleted'), success: true };
      router.goBack();
      this.setState({ loading: false });
    }).catch(() => {
      store.snackbar = { active: true, message: __('An error occurred while deleting reply'), success: false, dismissAfter: 5000 };
      this.setState({ loading: false });
    });
  }

  render() {
    const { router } = this.props;

    return (
      <Controller
        id="ReplyDelete"
        delete
        modal
        title={__('Delete reply?')}
        content={
          <div style={{ display: 'grid' }}>
            {
              this.props.location.query.deleteForEveryone === 'true' ?
                <span>{__('Are you sure you want to delete this reply for all recipients? This action cannot be undone.')}</span>
                :
                null
            }
          </div>
        }
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => router.goBack()}
          />,
          <Button
            data-action="submit"
            key={1}
            negative
            content={__('Delete')}
            onClick={() => {
              this.request();
            }}
            loading={this.state.loading}
            disabled={this.state.loading}
          />
        ]}
        onClose={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
